<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      :title="$t('menu.menuitems.reports.text')"
    />
    <b-tabs
      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
      justified
      nav-class="nav-tabs-custom"
      content-class="p-3 text-muted"
    >
      <!-- start openinig balances -->
      <b-tab active>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("reports.tabs.standard")
          }}</span>
        </template>

        <div style="background-color: #fff" class="p-4 accrodion">
          <div class="d-flex justify-content-between align-items-center">
            <span> {{ $t("reports.types.business") }} </span>
            <i
              style="font-size: 25px"
              class="bx bx-chevron-down font-bold text-large"
            ></i>
          </div>
          <div class="accordoin-content py-2 ms-2">
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/balance_sheet">{{
                  $t("reports.balance_sheet")
                }}</router-link>
              </span>
            </div>
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/income-statement">{{
                  $t("reports.income_statement")
                }}</router-link>
              </span>
            </div>
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/cash-flow-statement">{{
                  $t("reports.cahsflow_statement")
                }}</router-link>
              </span>
            </div>
          </div>
        </div>
        <br />
        <!-- start ows -->
        <div style="background-color: #fff" class="p-4 accrodion">
          <div class="d-flex justify-content-between align-items-center">
            <span> {{ $t("reports.types.ows") }} </span>
            <i
              style="font-size: 25px"
              class="bx bx-chevron-down font-bold text-large"
            ></i>
          </div>
          <div class="accordoin-content py-2 ms-2">
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/debtors"
                  >{{ $t("reports.creditors") }}
                </router-link>
              </span>
            </div>
          </div>
        </div>
        <br />
        <!-- end sales and customers -->
        <!-- start owe -->
        <div style="background-color: #fff" class="p-4 accrodion">
          <div class="d-flex justify-content-between align-items-center">
            <span> {{ $t("reports.types.own") }} </span>
            <i
              style="font-size: 25px"
              class="bx bx-chevron-down font-bold text-large"
            ></i>
          </div>
          <div class="accordoin-content py-2 ms-2">
            <!-- <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/income-statement"
                  >Account payable aging detail
                </router-link>
              </span>
            </div> -->
            <!-- <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/income-statement"
                  >Account payable aging summary
                </router-link>
              </span>
            </div> -->
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/creditors"
                  >{{ $t("reports.debtors") }}
                </router-link>
              </span>
            </div>
          </div>
        </div>
        <br />
        <!-- end owe -->
        <!-- start for my accountant -->
        <div style="background-color: #fff" class="p-4 accrodion">
          <div class="d-flex justify-content-between align-items-center">
            <span> {{ $t("reports.types.my-accountant") }} </span>
            <i
              style="font-size: 25px"
              class="bx bx-chevron-down font-bold text-large"
            ></i>
          </div>
          <div class="accordoin-content py-2 ms-2">
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/balance_sheet">{{
                  $t("reports.balance_sheet")
                }}</router-link>
              </span>
            </div>
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/general_ledger">{{
                  $t("reports.genral")
                }}</router-link>
              </span>
            </div>
            <!-- <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/bill_age_analysis_report"
                  >Bill Age Analisis</router-link
                >
              </span>
            </div> -->
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/budget_vs_actual_report">{{
                  $t("reports.budget_is_actual")
                }}</router-link>
              </span>
            </div>
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/income-statement">{{
                  $t("reports.income_statement")
                }}</router-link>
              </span>
            </div>
            <div class="py-2" style="border-bottom: 1px solid #ccc">
              <span class="px-2">
                <router-link to="/trial_balance">{{
                  $t("reports.trial_balance")
                }}</router-link>
              </span>
            </div>
          </div>
        </div>
        <!-- end for my accountant -->
      </b-tab>
      <!-- end openinig balances -->
      <!-- start openinig balances -->
      <b-tab>
        <template v-slot:title>
          <span
            :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            class="d-inline-block d-sm-none"
          >
            <i class="fas fa-home"></i>
          </span>
          <span class="d-none d-sm-inline-block">{{
            $t("reports.tabs.custom")
          }}</span>
        </template>
        <OpeningBalances />
      </b-tab>
      <!-- end openinig balances -->
    </b-tabs>
  </Layout>
</template>
<style scoped></style>
